import React, { PureComponent } from 'react';
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend
} from 'recharts';
import PARAMS from "utils/PARAMS";

export default class RadarChartComponent extends PureComponent {

  render() {
    return (
      <RadarChart outerRadius={200} position="center" width={this.props.width} height={this.props.height} data={this.props.data}>
        <PolarGrid cx={500} cx={350} />
        <PolarAngleAxis dataKey="subject" width={160} margin={50} tick={{ fontSize: 16, fontFamily: "Arial", fontWeigth: 400, height: 100 }}/>
        <PolarRadiusAxis axisLine={false} tick={false} tickCount={1} domain={[0, 10]}/>
        <Radar name="Resultado Personal" dataKey="A" stroke={this.props.stroke1} fill={this.props.fill1} fillOpacity={0.8} />
        <Radar name="Resultado Media" dataKey="B" stroke={this.props.stroke2} fill={this.props.fill2} fillOpacity={0.6} />
        <Legend />
      </RadarChart>
    );
  }
}
