import React, { PureComponent } from 'react';
import {
  ComposedChart, Scatter, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, Cell
} from 'recharts';
import PARAMS from "utils/PARAMS";

export default class VerticalBarChartComponent extends PureComponent {

  render() {
    return (
      <ComposedChart
        layout="vertical"
        width={820}
        height={600}
        data={this.props.data}
        margin={{
          top: 20, right: 20, bottom: 40, left: 20,
        }}
       >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" padding={{ top: 20, bottom: 20 }} width={200} tick={{ fontSize: 18, fontWeight: 'bold' }}/>
        <Tooltip />
        <Legend iconType="diamond" />
        <Bar dataKey="Resultado Personal" barSize={25} fill="transparent" label={{ position: 'right', paddingBottom: '15px' }}>
          {
            this.props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={this.props.dataColor[index % 20]}/>
            ))
          }
        </Bar>
        {this.props.showMedia &&
          <Scatter dataKey="Resultado Media" fill={this.props.colorScatter} stroke="#000" shape="diamond"/>
        }
      </ComposedChart>
    );
  }
}
